
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

interface newItemData {
  itemTitle: string;
  itemIATA: string;
  itemICAO: string;
  itemCallsign: string;
  itemAlias: string;
  itemCountry: string;

}

export default defineComponent({
  name: "new-airline-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
    }
  },

  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);




    const newItemData = ref<newItemData>({
      itemTitle: "",
      itemIATA: "",
      itemICAO: "",
      itemCallsign: "",
      itemAlias: "N",
      itemCountry: "Turkey",

    });

    const validationSchema = Yup.object().shape({
      itemTitle: Yup.string().required().label("Havayolu Adı"),
      itemIATA: Yup.string().required().label("IATA Kodu"),
      itemICAO: Yup.string().required().label("ICAO Kodu"),
      itemCallsign: Yup.string().required().label("Çağrı Kodu"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");


      const payload1 = {
        Title: newItemData.value.itemTitle,
        IATA: newItemData.value.itemIATA,
        ICAO: newItemData.value.itemICAO,
        Callsign: newItemData.value.itemCallsign,
        Country: newItemData.value.itemCountry,
        Alias: newItemData.value.itemAlias,
      }
      //console.log(payload1);

      store.dispatch(Actions.AIRLINE_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getAirlineErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };



    return {
      newItemData,
      validationSchema,
      submit,
      submitButtonRef,
      newRoomModalRef,
    };
  },
});
